import React from 'react'
import ReactPlayer from 'react-player'

/*
   Responsive video embed: /scss/components/_video.scss
   ${props.video} takes videoID <Video videoID="K94b_-_ayMU" />
*/

const Video = (props) => (
    <div className='player-wrapper'>
        <ReactPlayer
            className='embed-container'
            url={`https://www.youtube.com/embed/${props.videoID}`}
            frameborder='0'
        />
    </div>
)

export default Video