import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../../components/layout'
import Video from '../../components/Video'

const Generic = (props) => (
    <Layout>
        <div>
            <Helmet>
                <title>Voice User Interface | Jonathan Agoot</title>
                <meta name="description" content="voice user interface, vui, voice app, voice application, amazon alexa, amazon echo, amazon echo show, google assistant, google cloud platform, google cloud" />
            </Helmet>
            <div id="main" className="alt">
                <section id="one" className="tiles">
                    <div className="inner">
                        <header className="major">
                            <h1>Voice User Interfaces</h1>
                        </header>
                        <hr />
                        <header className="major">
                            <h3>Cell Culture Calculator</h3>
                        </header>
                        <div className="grid-wrapper">
                            <div className="col-6">
                                <p><b>December 29, 2017:</b> This voice app helps scientists with cell culture preparation so they can get the yield that they want without waste.</p>
                                <p><b>Built On:</b> <code>Amazon Alexa SDK</code><code>Amazon Echo Show</code><code>Amazon Web Services</code></p>
                            </div>
                            <div className="col-6">
                                <Video videoID="03nSMf9IHY4" />
                            </div>
                        </div>
                        <hr />
                        <header className="major">
                            <h3>Dilution Calculator Protoype</h3>
                        </header>
                        <div className="grid-wrapper">
                            <div className="col-6">
                                <p><b>August 16, 2017:</b> I wanted to push the boundaries of emerging technologies with voice user interfaces. My customers at the time were scientists and worked in laboratories that were very sterile. Contamination of their experiments may result in lost time, effort, and money/future funding. And often times, scientists have gloves on and their hands are full... so why not use voice user interfaces?</p>
                                <p>I created this very light prototype so that I could get a conversation started with my business partners. Because of this prototype, we were able to create a more sophisticated prototype that would solve a genuine customer need while keeping the company on fringes of science and technology.</p>
                                <p><b>Built on:</b> <code>Alexa.js</code><code>Amazon Echo Show</code><code>Amazon Web Services</code></p>
                            </div>
                            <div className="col-6">
                                <Video videoID="K94b_-_ayMU" />
                            </div>
                        </div>
                        <hr />
                        <header className="major">
                            <h3>What's For Breakfast Protoype</h3>
                        </header>
                        <div className="grid-wrapper">
                            <div className="col-6">
                                <p><b>August 15, 2017:</b> I've been wanting to explore the frontiers of voice user interfaces from an opportunity perspective, so I thought "why not create a prototype". In this video, I was using the alexa.js built on node.js framework because the Alexa SDK developer experience was too verbose!</p>
                                <p><b>Built on:</b> <code>Alexa.js</code><code>Amazon Echo Show</code><code>Amazon Web Services</code></p>
                            </div>
                            <div className="col-6">
                                <Video videoID="yWw4qOr0G7Y" />
                            </div>
                        </div>
                        <hr />
                        <header className="major">
                            <h3>College Advisor Protoype</h3>
                        </header>
                        <div className="grid-wrapper">
                            <div className="col-6">
                                <p><b>November 13, 2018:</b> Due to the student loan "crisis" of $1.5 trillion dollars (and rising), I thought it may be of use to create a "College Advisor".</p>
                                <p>This Google Assistant will let the prospective student know if it's worth investing one's time and energy to commit to a loan.</p>
                                <p><b>Built on:</b> <code>Jovo Framework</code><code>Google Assistant</code><code>Google Actions</code><code>Google Cloud Platform</code></p>
                            </div>
                            <div className="col-6">
                                <Video videoID="QcU_2tdS4n4" />
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    </Layout >
)

export default Generic
